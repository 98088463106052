.hui-expansion-panel {
    width: 100%;
}

.hui-expansion-panel summary {
    max-width: 1080px;
    height: 44px;
    background-color: #060368;
    background-size: cover;
    border: none;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.hui-expansion-panel .summary-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    flex-grow: 1;
}

.hui-expansion-panel summary:hover {
    max-width: 1080px;
    height: 44px;
    background-color: #69469d;
    background-size: cover;
    border: none;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.hui-expansion-panel summary .title {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    font-weight: bold;
    text-align: left;
    height: 24px;
    font-family: var(--font-mrEaves);
    font-size: 21px;
    color: #ffffff;
    text-decoration: none solid rgb(255, 255, 255);
}

.hui-expansion-panel summary .title > h6 {
    margin: 0px;
    padding: 0px;
    font-weight: bold;
    text-align: left;
    height: 24px;
    font-family: var(--font-mrEaves);
    font-size: 21px;
    color: #ffffff;
    text-decoration: none solid rgb(255, 255, 255);
}

.hui-expansion-panel summary img {
    height: 25px;
    margin: 0px 10px 0px 10px;
}

.hui-expansion-panel details summary::-webkit-details-marker {
    display:none;
}