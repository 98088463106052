

.hui-switch {
	display: flex;
}

/* Hiding the actual check box first*/
.hui-switch input[type="checkbox"].switch  {
	position: absolute;
	opacity: 0;
}
/* Rounding the entire switch (both divs)*/
.hui-switch input[type="checkbox"].switch + div {
	vertical-align: middle;
	width: 40px;
	height: 20px;
	border-radius: 999px;
	background-color: #ebe9f4;
	-webkit-transition-duration: .4s;
	transition-duration: .4s;
	-webkit-transition-property: background-color, box-shadow;
	transition-property: background-color, box-shadow;
    cursor: pointer;
    border: solid #69469d 1px;
    box-shadow: inset 0px 1px 2px rgba(0,0,0,0.2);
}

/* keeps background of switch at this width*/
.hui-switch input[type="checkbox"].switch + div {
	width: 72px;
	height: 38px;
}

/* turns switch color darn when on*/
.hui-switch input[type="checkbox"].switch:checked + div {
	background-color: #060368;
}

/* Smaller white div inside background that moves when clicked*/
.hui-switch input[type="checkbox"].switch + div > div {
	float: left;
    width: 36px; height: 36px;
    border: 1px solid #cccccc;
	border-radius: inherit;
	background: #ffffff;
	-webkit-transition-timing-function: cubic-bezier(1,0,0,1);
	transition-timing-function: cubic-bezier(1,0,0,1);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: transform, background-color;
	transition-property: transform, background-color;
	pointer-events: none;
	margin-left: 1px;
}

/* This moves the smaller div to the right when clicked*/
.hui-switch input[type="checkbox"].switch:checked + div > div {
	-webkit-transform: translate3d(46px, 0, 0);
    transform: translate3d(33px, 0, 0);
}

