
.task-page-root {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    flex-grow: 1;
    width: 100%;
}

.task-page-intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    min-height: 250px;
}

.task-page-body {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: nowrap;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-style: solid;
    border-color: #a694c7;
}

.tabbar-root {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    min-height: 1000px;
    background-color: #ebe9f4;
    max-width: 255px;
    flex: 1 1 25%;
}

.task-panel-root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex: 3 3 75%;
    padding: 5px 20px;
}

.task-panel-row {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width: 100%;
    width: 100%;
}

@media only screen and (max-width: 1024px) {

    .task-page-body {
        flex-direction: column;
    }

    .tabbar-root {
        flex-direction: row;
        min-height: 77px;
        height: 77px;
        width: 100%;
        justify-content: center;
        flex: 0;
        max-width: 100%;
    }

    .task-panel-root {
        flex: 1;
    }
    .task-panel-row {
        width: 100%;
        padding: 5px 0px;
        max-width: 100%;
    }
}

@media only screen and (max-width: 420px) {
    #taskcard_2 {
        display: none;
    }
}