#dp {
  display: flex;
  justify-content: center;
  text-align: center;
  position: relative;
}

#dp input[type="date"] {
  width: 183px;
  height: 43px;
  border-radius: 5px;
  border: 1px solid #555555;
  font-size: 21px;
  font-weight: 400;
  font-family: var(--font-mrEaves);
  padding-left: 10px;
  color: #111111;
  background:#fff url(https://village-co-callback.s3.amazonaws.com/images/calendar.svg) calc(100% - 5px) 50% no-repeat;
}

@media (max-width: 675px) {
  #dp {
    width: 100%;
  }

  #dp input[type="date"] {
    width: 100%;
  }
}

#dp input[type="date"]::-webkit-inner-spin-button {
  display: none;
}

#dp input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
}

#dp #calendar-icon {
  position: absolute;
  right: 0;
  height: 100%;
  width: 40px;
  background: none;
}

#dp #calendar-icon:hover {
  background-color: none;
  cursor: pointer;
}


#dp .calendar {
  width: 300px;
  position: absolute;
  top: 46px;
  left: 0;
  z-index: 2000;
  display: flex;
  flex-direction: column;
  padding: 2px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 1px 2px 2px rgba(0,0,0,0.2);
}

#dp .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    color: #555555;
    font-family: var(--font-mrEaves);
    padding: 5px 0;
}

#dp #next-icon, #prev-icon {
  height: 40px;
  width: 40px;
}

#dp #next-icon > img, #prev-icon > img {
  height: 40px;
  width: 40px;
}

#dp .month {
    padding: 0 15px;
}

#dp .header {
  height: 26px;
  font-size: 14px;
  color: #555555;
  font-family: var(--font-mrEaves);
  font-weight: 400;
}

#dp table {
  width: 100%;
}

#dp .cell {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  font-family: arial, helvetica, sans-serif;
  font-size: 14px;
  color: #555555;
}

#dp .cell:hover {
  background-color: #69469d;
  color: white;
  cursor: pointer;
}

#dp .cell.active {
  background-color:#69469d;
  color: white;
}

#dp .date-picker-error {
  position: absolute;
  bottom: -10px;
  right: calc(50% - 50px);
  z-index: 3000;
}