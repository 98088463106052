.wiz-root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.wiz-stepper-empty {
    height: 36px;
    margin-top: 50px;
    
}

@media(max-width: 450px){
    .wiz-stepper-empty {
        margin-top: 50px;
    }
}

.wiz-header {
    left: 0px;
    top: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #ebe9f4;
    font-family: var(--font-mrsEaves);
    font-size: 18px;
    color: #060368;
    font-weight: 400;
    position: fixed;
    width: 100%;
    z-index: 1;
    margin-top: 0px;
    padding: 10px;
}

.wiz-footer {
    height: 57px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-top: 1px solid #ebe9f4;
    margin: 100px 25px 0px 25px;
}

.wiz-footer a {
    margin: 10px;
    font-size: 14px;
    color: #69489d;
    font-weight: 400;
    text-decoration: underline solid rgb(105, 72, 157);
}

.wiz-footer p {
    flex-grow: 1;
    text-align: right;
    margin: 0px 20px 0px 20px;
    font-size: 14px;
}

.beta-banner {
    align-self: center;
    width: 100%;
    height: 26px;
    background-color: #eeeeee;
    background-size: cover;
    font-family: var(--font-mrEaves); 
    font-size: 16px;
    color: #555555;
    text-align: center;
    padding-top: 10px;
    position: fixed;
    z-index: 1;
}